import { gql } from "@apollo/client";

const PENDING_TASKS_FINANCE = gql`
  query pendingTasksFinance {
    pendingTasksFinance {
        grantsRequested
        grantsInTransit
        floatingGrants
        incomingTransactions
        outgoingTransactions
        newUsers
        onboardingUsers
        orgReview
        docReview
    }
  }
`;

const VALIDATE_PASSWORD_RESET_TOKEN = gql`
query ValidatePasswordResetToken($passwordResetToken: String!) {
  validatePasswordResetToken(passwordResetToken: $passwordResetToken)
}`;


export { PENDING_TASKS_FINANCE, VALIDATE_PASSWORD_RESET_TOKEN };
