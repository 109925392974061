import React, { useEffect, useState, useCallback, lazy, Suspense } from "react";
import Auth from "./../utils/auth";
import DelayedFallback from "../components/loading/DelayedFallback";

const ExpiredSessionModal = lazy(() =>
  import("./../../src/components/internalAssets/modals/ExpiredSessionModal")
);

const LoggedInCountdownTimer = ({ initialCountdown, logoutAlertTime, handleLogout }) => {
  const [countdown, setCountdown] = useState(initialCountdown);
  const [showModal, setShowModal] = useState(false);
  const [triggeredModal, setTriggeredModal] = useState(false);

  const countdownTime = useCallback(() => {
    const tokensExpiration = Auth.getTokensExpiration();
    if (!tokensExpiration) {
      console.error("Failed to get token expiration.");
      handleLogout();
    }
    const { accessTokenExp } = tokensExpiration;
    const currentTime = Math.floor(Date.now() / 1000);
    return accessTokenExp - currentTime;
  }, [handleLogout]);

  useEffect(() => {
    if (countdown <= 0) {
      setCountdown(0);
      handleLogout();
    } else {
      const interval = setInterval(() => {
        setCountdown(countdownTime());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countdown, countdownTime, handleLogout]);

  useEffect(() => {
    if (countdown && countdown <= logoutAlertTime && !showModal && !triggeredModal) {
      setShowModal(true);
      setTriggeredModal(true);
    } else if (countdown && countdown > logoutAlertTime && showModal) {
      setShowModal(false);
      setTriggeredModal(false);
    }
  }, [countdown, logoutAlertTime, showModal, triggeredModal]);

  return (
    <>
      {showModal && (
        <Suspense fallback={<DelayedFallback />}>
          <ExpiredSessionModal
            onClose={() => {
              setShowModal(false);
              setTriggeredModal(false);
            }}
            showModal={showModal}
            countdown={countdown}
            countdownTime={countdownTime}
            handleLogout={handleLogout}
          />
        </Suspense>
      )}
    </>
  );
};

export default LoggedInCountdownTimer;
