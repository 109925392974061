import { Box, Typography, CircularProgress } from "@mui/material";

export default function CircularLoading({
  boxSx,
  loaderSx,
  loading,
  size,
  centeredButton,
  fallback,
}) {
  const combinedBoxSx = fallback
    ? {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...boxSx,
      }
    : boxSx;

  return (
    <>
      {combinedBoxSx && (
        <Box sx={{ ...combinedBoxSx }}>
          <CircularProgress sx={{ ...loaderSx }} size={size} />
          {loading && (
            <Typography variant="body2" sx={{ mt: 2, pl: 1 }}>
              Loading...
            </Typography>
          )}
        </Box>
      )}
      {loaderSx && (
        <>
          <CircularProgress sx={{ ...loaderSx }} size={size} />
          {loading && (
            <Typography variant="body2" sx={{ mt: 2, pl: 1 }}>
              Loading...
            </Typography>
          )}
        </>
      )}
      {centeredButton && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress sx={{ color: centeredButton?.color || "white" }} size={size} />
        </Box>
      )}
    </>
  );
}
