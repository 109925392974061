/**
 * DelayedFallback Component
 *
 * This component provides a delayed loading fallback for Suspense.
 * Instead of immediately displaying a loading spinner, it waits for a 
 * specified delay (default: 300ms) before rendering `CircularLoading`.
 * 
 * Purpose:
 * - Prevents flickering when navigating between pages with quick loads.
 * - Ensures a smoother user experience by only showing a loader if the 
 *   main component takes longer than expected to load.
 *
 * Props:
 * @param {number} delay - The delay in milliseconds before displaying the fallback (default: 300ms).
 *
 * Usage:
 * ```
 * <Suspense fallback={<DelayedFallback delay={400} />}>
 *   <MyComponent />
 * </Suspense>
 * ```
 */

import CircularLoading from "./CircularLoading";

const { useState, useEffect } = require("react");

export default function DelayedFallback({ delay = 300 }) {
  const [showFallback, setShowFallback] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowFallback(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return showFallback ? <CircularLoading loading={true} fallback={true} /> : null;
}
