// UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { QUERY_USER_BY_ID } from "./graphQL/queries";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [openNav, setOpenNav] = useState(false);
  const [contextUserData, setContextUserData] = useState();
  const [loggedInUser, setLoggedInUser] = useState()
  const [contextUserId, setContextUserId] = useState(() => {
    // Check if the state exists in local storage
    const storedState = sessionStorage.getItem("contextUserId");
    console.log(storedState);
    return storedState ? JSON.parse(storedState) : ""; // Provide your initial state value
  });

  const [queryUserById, { error, refetch }] = useLazyQuery(QUERY_USER_BY_ID, {
    // Avoids running into cache issue **DO NOT REMOVE**
    fetchPolicy: "network-only",
  });

  const [contextProduct, setContextProduct] = useState(() => {
    // Check if the state exists in local storage
    const storedState = sessionStorage.getItem("contextProduct");
    console.log(storedState);
    return storedState ? JSON.parse(storedState) : 0; // Provide your initial state value
  });

  useEffect(() => {
    // Save the state to local storage whenever it changes
    sessionStorage.setItem("contextUserId", JSON.stringify(contextUserId));
  }, [contextUserId]);

  useEffect(() => {
    // Save the state to local storage whenever it changes
    sessionStorage.setItem("contextProduct", JSON.stringify(contextProduct));
  }, [contextProduct]);

  const updateContextUser = async (id) => {
    if (id === "") {
      setContextUserId("");
      setContextUserData("");
    } else {
      try {
        const queryResponse = await queryUserById({
          variables: {
            userId: id,
          },
        });
        console.log("Context response: ", queryResponse.data.findUserById)
        setContextUserId(id);
        setContextUserData(queryResponse.data.findUserById);
      } catch (e) {
        console.log("queryById fail: ", e);
        console.log(error);
        throw new Error("queryById err:", error);
      }
    }
  };

  return (
    <UserContext.Provider
      value={{
        openNav,
        setOpenNav,
        contextUserData,
        contextProduct,
        setContextProduct,
        contextUserId,
        updateContextUser,
        refetch,
        loggedInUser,
        setLoggedInUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
