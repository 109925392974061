export default function GetContrastColor(rgb) {
  // Convert HEX to RGB
  // const rgb = hexToRgb(hexColor);
  if (!rgb) return "#FFFFFF"; // Default to white if conversion fails

  let [r, g, b] = rgb;

  // Convert to luminance
  r = r / 255;
  g = g / 255;
  b = b / 255;

  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luminance > 0.45 ? "#000000" : "#FFFFFF"; // Black for light backgrounds, White for dark
}
