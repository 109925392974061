export default function CapitalFormatter(word) {
  if (word?.length === 0) {
    return word;
  } else if (!word) {
    return "N/A"
  }

  // Capitalize the first letter and combine with the rest of the word
  let capitalizedWord = word?.charAt(0)?.toUpperCase() + word?.slice(1);

  return capitalizedWord;
}
