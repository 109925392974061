import { gql } from "@apollo/client";
import { sharedFeeTransactionFields } from "../fragments/feeTransaction";

const FEE_TRANSACTIONS = gql`
  query FeeTransactions($productId: ID, $assetId: ID, $transactionId: ID) {
    feeTransactions(productId: $productId, assetId: $assetId, transactionId: $transactionId) {
      ... on AltInvestmentFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on BrandingFeeTransaction {
        _id
        createdAt
        dateReceived
        dateRequested
        feeType
        receivedAmount
        requestedAmount
        status
        waivedAmount
        meta {
          notes
        }
        product {
          ... on BrandingProduct {
            _id
            name
          }
        }
        entities {
          _id
          entity {
            ... on FirmEntity {
              _id
              entityName
            }
            ... on PartnerEntity {
              _id
              entityName
            }
            ... on BranchEntity {
              _id
              entityName
            }
          }
        }
      }
      ... on ClatAdminFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on DafAdminFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on DafArrearFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on EntityDueDiligenceFeeTransaction {
        ${sharedFeeTransactionFields}
        entity {
          ... on OrganizationEntity {
          _id
          entityName
          }
        }
      }
      ... on ExpediteFeeTransaction {
        ${sharedFeeTransactionFields}
        transaction {
          ... on GrantTransaction {
            _id
            transactionAmount
          }
        }
      }
      ... on GiftAgreementFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on ImpactInvestmentFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on MiscFeeTransaction {
        ${sharedFeeTransactionFields}
        description
      }
    }
  }
`;

const FEE_TRANSACTIONS_YEAR_QUARTER = gql`
  query FeeTransactionsYearQuarter($year: Int, $quarter: Int) {
    feeTransactionsYearQuarter(year: $year, quarter: $quarter) {
      ... on AltInvestmentFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on ClatAdminFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on DafAdminFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on DafArrearFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on EntityDueDiligenceFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on ExpediteFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on GiftAgreementFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on ImpactInvestmentFeeTransaction {
        ${sharedFeeTransactionFields}
      }
      ... on MiscFeeTransaction {
        ${sharedFeeTransactionFields}
      }
    }
  }
`;

const FEE_TRANSACTIONS_BY_FIRM = gql`
  query FeeTransactionsByFirm($entityId: ID!, $quarter: Int!, $year: Int!) {
    feeTransactionsByFirm(entityId: $entityId, quarter: $quarter, year: $year) {
      ... on AltInvestmentFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on ClatAdminFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on DafAdminFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on DafArrearFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on EntityDueDiligenceFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on ExpediteFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on GiftAgreementFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on ImpactInvestmentFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
      ... on MiscFeeTransaction {
        _id
        year
        quarter
        feeType
        status
      }
    }
  }
`;

const FEE_TRANSACTIONS_WITHOUT_ADVISOR = gql`
  query FeeTransactionsByDonorWithoutAdvisor($quarter: Int!, $year: Int!) {
    feeTransactionsByDonorWithoutAdvisor(quarter: $quarter, year: $year) {
      ... on AltInvestmentFeeTransaction {
        _id
      }
      ... on ClatAdminFeeTransaction {
        _id
      }
      ... on DafAdminFeeTransaction {
        _id
      }
      ... on DafArrearFeeTransaction {
        _id
      }
      ... on EntityDueDiligenceFeeTransaction {
        _id
      }
      ... on ExpediteFeeTransaction {
        _id
      }
      ... on GiftAgreementFeeTransaction {
        _id
      }
      ... on ImpactInvestmentFeeTransaction {
        _id
      }
      ... on MiscFeeTransaction {
        _id
      }
    }
  }
`;

export {
  FEE_TRANSACTIONS,
  FEE_TRANSACTIONS_YEAR_QUARTER,
  FEE_TRANSACTIONS_BY_FIRM,
  FEE_TRANSACTIONS_WITHOUT_ADVISOR,
};
