import { gql } from "@apollo/client";

const FEE_BY_PRODUCT = gql`
  query FeeByProduct($productId: ID!, $feeType: FeeType) {
    feeByProduct(productId: $productId, feeType: $feeType) {
      ... on ClatFee {
        _id
        createdAt
        defaultFee
        defaultFeeName
        feeName
        feeType
        product
        tiers {
          feePercentage
          minAmount
        }
        isWaived
        meta {
          notes
        }
        updatedAt
        yearlyMinimumFee
      }
      ... on DafFee {
        _id
        createdAt
        defaultFee
        defaultFeeName
        feeName
        feeType
        product
        tiers {
          feePercentage
          minAmount
        }
        isWaived
        meta {
          notes
        }
        updatedAt
        yearlyMinimumFee
      }
      ... on BrandingFee {
        _id
        status
        createdAt
        feeAmount
        startDate
        nextExecutionDate
        cancellationDate
        isWaived
        meta {
          notes
        }
      }
    }
  }
`;

const FEE_TEMPLATES_BY_TYPE = gql`
query FeeTemplatesByType($feeType: FeeType!) {
  feeTemplatesByType(feeType: $feeType) {
    ... on DafFee {
      _id
      feeName
      feeType
      tiers {
        currentTierTotal
        feePercentage
        maxAmount
        minAmount
      }
      yearlyMinimumFee
    }
    ... on ClatFee {
      _id
      feeType
      feeName
      tiers {
        currentTierTotal
        feePercentage
        maxAmount
        minAmount
      }
      yearlyMinimumFee
    }
  }
}
`;

export { FEE_BY_PRODUCT, FEE_TEMPLATES_BY_TYPE };
