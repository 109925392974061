import { gql } from "@apollo/client";

const FIND_PRODUCT = gql`
  query FindProduct($productId: ID) {
    findProduct(productId: $productId) {
      ... on BrandingProduct {
        _id
        createdAt
        homeUrl
        logo
        smallLogo
        name
        primaryColor
        productType
        secondaryColor
        subdomain
        backgroundColor
      }
    }
  }
`;

const FIND_ALL_PRODUCTS = gql`
  query FindAllProducts($page: Int, $pageSize: Int) {
    findAllProducts(page: $page, pageSize: $pageSize) {
      pageInfo {
        currentPage
        pageSize
        totalDocuments
        totalPages
      }
      products {
        ... on DafProduct {
          _id
          name
          productType
          totals {
            availableToGrant
            date
            cash
            crypto
            stock
            alternativeAssets
            pendingGrantPayments
            totalContributed
            totalGranted
            ytdContributed
            ytdGranted
          }
          users {
            _id
            associationType
            user {
              ... on InternalUser {
                _id
                firstName
                lastName
              }
              ... on DonorUser {
                _id
                firstName
                lastName
              }
              ... on FirmUser {
                _id
                firstName
                lastName
              }
              ... on EntityUser {
                _id
                firstName
                lastName
              }
            }
          }
        }
        ... on ClatProduct {
          _id
          name
          productType
          totals {
            date
            cash
            stock
          }
          users {
            _id
            associationType
            user {
              ... on InternalUser {
                _id
                firstName
                lastName
              }
              ... on DonorUser {
                _id
                firstName
                lastName
              }
              ... on FirmUser {
                _id
                firstName
                lastName
              }
              ... on EntityUser {
                _id
                firstName
                lastName
              }
            }
          }
        }
        ... on BrandingProduct {
          _id
          name
          productType
          users {
            _id
            associationType
            user {
              ... on InternalUser {
                _id
                firstName
                lastName
              }
              ... on DonorUser {
                _id
                firstName
                lastName
              }
              ... on FirmUser {
                _id
                firstName
                lastName
              }
              ... on EntityUser {
                _id
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
`;

const FIND_PRODUCT_ADDITIONAL_USERS = gql`
  query FindProductAdditionalUsers($productId: ID!) {
    findProductAdditionalUsers(productId: $productId) {
      ... on DafProduct {
        _id
        name
        users {
          _id
          associationType
          inviteStatus
          relationship
          expectedFirstName
          expectedLastName
          expectedEmail
          user {
            ... on DonorUser {
              _id
              firstName
              lastName
              email
            }
          }
        }
      }
    }
  }
`;

const FIND_BRANDING = gql`
  query FindBranding($subdomain: String!) {
    findBranding(subdomain: $subdomain) {
      _id
      homeUrl
      logo
      smallLogo
      primaryColor
      secondaryColor
      backgroundColor
    }
  }
`;

const FIND_ENTITY_PRODUCTS = gql`
  query FindEntityProducts($entityId: ID!) {
    findEntityProducts(entityId: $entityId) {
      _id
      product {
        ... on DafProduct {
          _id
          name
          productType
        }
        ... on ClatProduct {
          _id
          name
          productType
        }
        ... on BrandingProduct {
          _id
          name
          productType
        }
      }
    }
  }
`;

export { FIND_PRODUCT, FIND_ALL_PRODUCTS, FIND_PRODUCT_ADDITIONAL_USERS, FIND_BRANDING, FIND_ENTITY_PRODUCTS };
