import { createTheme } from "@mui/material";
import { useBrandingContext } from "./BrandingContext";
import GetContrastColor from "./utils/helpers/GetContrastColor";

const useCustomTheme = () => {
  const { loading, error, branding } = useBrandingContext();

  const primaryContrast = GetContrastColor(branding?.primaryColor ?? null);
  const secondaryContrast = GetContrastColor(branding?.secondaryColor ?? null);

  let primaryMain = "#FFFFFF";
  let primaryHover = "#FFFFFF";
  let primaryOpposite = "#FFFFFF";
  let primaryOppositeHover = "#FFFFFF";
  let secondaryMain = "#FFFFFF";
  let secondaryHover = "#FFFFFF";
  let backgroundMain = "#FFFFFF";
  let backgroundHover = "#FFFFFF";
  let accent1 = "#EFEFEF";
  let accent2 = "#A5A5A5";
  let primaryGreen = "#61AF2E";

  switch (window.location.hostname) {
    case "portal.uicharitable.org":
      primaryMain = "#000F59"; // "#100E0D" old black
      primaryHover = "rgba(0, 15, 89, 0.75)"; // "rgba(16, 14, 13, 0.75)" old black
      secondaryMain = "#0022CC";
      secondaryHover = "rgba(1, 34, 204, 0.75)";
      backgroundMain = "#EEF1F7";
      backgroundHover = "rgba(238, 241, 247, 0.75)";
      break;
    default:
      if (branding) {
        primaryMain = `rgb(${branding?.primaryColor?.join(",")})`; //"#000F59"; // "#100E0D" old black
        primaryHover = `rgba(${branding?.primaryColor?.join(",")}, 0.75)`; //"rgba(0, 15, 89, 0.75)"; // "rgba(16, 14, 13, 0.75)" old black
        secondaryMain = `rgb(${branding?.secondaryColor?.join(",")})`; //"#FFB806";
        secondaryHover = `rgba(${branding?.secondaryColor?.join(",")}, 0.75)`; //"rgba(255, 184, 6, 0.75)";
        backgroundMain =
          branding?.backgroundColor?.length > 0
            ? `rgb(${branding?.backgroundColor?.join(",")})`
            : "#EEF1F7";
        backgroundHover =
          branding?.backgroundColor?.length > 0
            ? `rgba(${branding?.backgroundColor?.join(",")}, 0.75)`
            : "rgba(238, 241, 247, 0.75)";
      } else if (!branding && !loading) {
        primaryMain = "#000F59"; // "#100E0D" old black
        primaryHover = "rgba(0, 15, 89, 0.75)"; // "rgba(16, 14, 13, 0.75)" old black
        secondaryMain = "#0022CC";
        secondaryHover = "rgba(1, 34, 204, 0.75)";
        backgroundMain = "#EEF1F7";
        backgroundHover = "rgba(238, 241, 247, 0.75)";
      }
      break;
  }
  return createTheme({
    typography: {
      fontFamily: "Figtree", // Apply the Figtree font to all text in the theme
    },
    components: {
      // POTENTIAL FUTURE UPDATES
      // MuiCard: {
      //   styleOverrides: {
      //     root: {
      //       border: "1px solid lightgrey",
      //       boxShadow: "none",
      //       borderRadius: "10px",
      //     },
      //   },
      // },
      MuiAccordion: {
        styleOverrides: {
          root: {
            backgroundColor: backgroundMain,
            border: "none",
            boxShadow: "none"
          }
        },
        variants: [
          {
            props: { variant: "borderlessHover" },
            style: {
              border: "none",
              boxShadow: "none",
              "&.Mui-expanded": {
                margin: "0",
              },
              "&.Mui-expanded:before": {
                opacity: "1 !important",
              },
            },
          },
        ],
      },
      MuiAccordionSummary: {
        variants: [
          {
            props: { variant: "borderlessHover" },
            style: {
              pb: 1,
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                cursor: "pointer",
              },
            },
          },
        ],
      },
      MuiBox: {
        styleOverrides: {
          root: {
            backgroundColor: backgroundMain,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            // all links default to primary or black
            fontFamily: "Figtree",
            color: primaryContrast === "#000000" ? "#000000" : primaryMain,
          },
        },
        variants: [
          {
            props: { variant: "link" },
            style: {
              color: "black",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "linkContrast" },
            style: {
              color: "white",
              fontFamily: "Figtree",
            },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: "h1" },
            style: {
              color: "black",
              fontWeight: 600,
              fontSize: "32px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h1Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 600,
              fontSize: "32px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h2" },
            style: {
              color: "black",
              fontWeight: 600,
              fontSize: "28px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h2Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 600,
              fontSize: "28px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h3" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "26px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h3Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 500,
              fontSize: "26px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h4" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "22px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h4Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 500,
              fontSize: "22px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h5" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "20px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h5Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 500,
              fontSize: "20px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h6" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "18px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "h6Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 500,
              fontSize: "18px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "subtitle1" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "subtitle1Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "subtitle2" },
            style: {
              color: "black",
              fontWeight: 500,
              fontSize: "14px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "subtitle2Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 500,
              fontSize: "14px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "body1" },
            style: {
              color: "black",
              fontWeight: 600,
              fontSize: "16px",
              fontFamily: "Figtree",
              lineHeight: "22px"
            },
          },
          {
            props: { variant: "body1Contrast" },
            style: {
              color: primaryContrast === "#000000" ? "#000000" : primaryMain,
              fontWeight: 600,
              fontSize: "16px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "body2" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Figtree",
              lineHeight: "22px"
            },
          },
          {
            props: { variant: "body2Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "body3" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "body3Contrast" },
            style: {
              color: primaryContrast,
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "caption" },
            style: {
              color: "black",
              fontWeight: 400,
              fontSize: "12px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "captionContrast" },
            style: {
              color: primaryContrast,
              fontWeight: 400,
              fontSize: "12px",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "overline" },
            style: {
              color: "black",
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              textTransform: "uppercase",
              fontFamily: "Figtree",
            },
          },
          {
            props: { variant: "overlineContrast" },
            style: {
              color: primaryContrast,
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "24px",
              textTransform: "uppercase",
              fontFamily: "Figtree",
            },
          },
        ],
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.color === "primary" && {
              color: primaryContrast === "#000000" ? "#000000" : primaryMain,
            }),
          }),
        },
        variants: [
          {
            props: { variant: "Button1" },
            style: {
              backgroundColor: primaryMain,
              color: primaryContrast,
              textTransform: "none",
              fontSize: "16px",
              height: "44px",
              borderRadius: "5px",
              border: '2px solid',
              borderColor: primaryContrast === "#000000" ? "#000000" : primaryMain,
              ":hover": {
                backgroundColor: "white",
                color: primaryContrast === "#000000" ? "#000000" : primaryMain,
                border: '2px solid',
                borderColor: primaryContrast === "#000000" ? "#000000" : primaryMain,
                fontWeight: 600
              },
              ":disabled": {
                color: primaryContrast,
              },
            },
          },
          {
            props: { variant: "Button2" },
            style: {
              backgroundColor: secondaryMain,
              color: secondaryContrast,
              textTransform: "none",
              fontSize: "16px",
              height: "44px",
              borderRadius: "5px",
              border: '2px solid',
              borderColor: secondaryContrast === "#000000" ? "#000000" : secondaryMain,
              ":hover": {
                backgroundColor: "white",
                color: secondaryContrast === "#000000" ? "#000000" : secondaryMain,
                border: '2px solid',
                borderColor: secondaryContrast === "#000000" ? "#000000" : secondaryMain,
                fontWeight: 600
              },
              ":disabled": {
                color: secondaryContrast,
              },
            },
          },
        ],
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "white",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: primaryContrast === "#000000" ? "#000000" : primaryMain, // Changes label color when focused
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: primaryContrast === "#000000" ? "#000000" : primaryMain, // Changes border on focus
            },
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: primaryContrast === "#000000" ? "#000000" : primaryMain,
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: primaryContrast === "#000000" ? "#000000" : primaryMain, // Change indicator (underline) color
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            "&.Mui-checked": {
              color: primaryContrast === "#000000" ? "#000000" : primaryMain,
            },
          },
        },
      },
      MuiCard: {
        variants: [
          {
            props: { variant: "Card1" },
            style: {
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#E0E3E7",
              borderRadius: "10px",
              backgroundColor: "white",
              boxShadow: "0px 4px 8px -3px rgba(0, 0, 0, 0.25)",
            },
          },
        ],
      },
    },
    palette: {
      primary: {
        main: primaryContrast === "#000000" ? "#000000" : primaryMain,
        hover: primaryHover,
        opposite: primaryOpposite,
        oppositeHover: primaryOppositeHover,
        green: primaryGreen,
      },
      secondary: {
        main: secondaryMain,
        hover: secondaryHover,
      },
      background: {
        main: backgroundMain,
        hover: backgroundHover,
      },
      accent1: {
        main: accent1,
      },
      accent2: {
        main: accent2,
      },
      sheets: {
        main: "#188038",
        hover: "rgba(24, 128, 56, 0.5)",
      },
    },
  });
};

export default useCustomTheme;
