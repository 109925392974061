import React, { createContext, useContext, useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { FIND_BRANDING } from "./graphQL/queries";
import ExtractSubdomain from "./utils/helpers/ExtractSubdomain";
import CapitalFormatter from "./utils/helpers/CapitalFormatter";

const BrandingContext = createContext();

export const BrandingProvider = ({ children }) => {
  const [branding, setBranding] = useState(null);
  const subdomain = ExtractSubdomain(window.location.hostname);
  const { loading, error, data } = useQuery(FIND_BRANDING, { variables: { subdomain } });

  useEffect(() => {
    if (loading) {
      console.log("Loading...");
    } else if (error) {
      console.error("Error:", error.message);
    } else if (data) {
      setBranding(data.findBranding);
    }
  }, [loading, error, data]);

  useEffect(() => {
    document.title = `${CapitalFormatter(subdomain || "portal")} | UI Charitable Advisors`;
  }, [subdomain]);

  return (
    <BrandingContext.Provider value={{ loading, error, branding }}>
      {children}
    </BrandingContext.Provider>
  );
};

export const useBrandingContext = () => useContext(BrandingContext);
