import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { UserProvider, useUserContext } from "./UserContext";
import { BrandingProvider } from "./BrandingContext";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { BrowserRouter } from "react-router-dom";
// import { AuthProvider } from "./context/AuthProvider";

import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { Provider } from "react-redux";
import store from "./utils/redux/store";

import axios from "axios";

import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.info = () => {};

  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      // Sentry.feedbackIntegration({
      //   // Additional SDK configuration goes in here, for example:
      //   colorScheme: "light",
      // }),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["uicharitable.org", /^https:\/\/(?:.*\.)?uicharitable\.org/], // /^https:\/\/yourserver\.io\/api/
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 50%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const httpLink = createHttpLink({
  uri: "/graphql",
});

// pass auth token from local storage to request headers for graphql and axios
// axios.defaults.headers.common["authentication"] = "Bearer " + localStorage.getItem("idToken"); // OLD
axios.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("idToken");
  config.headers.authentication = `Bearer ${accessToken}`;

  return config;
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem("idToken");

  if (!token) {
    await new Promise((resolve) => setTimeout(resolve, 10)); // Delay to allow token storage
    token = localStorage.getItem("idToken"); // Re-check after delay
  }

  console.log("token to add to auth header", token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authentication: token ? `Bearer ${token}` : "",
    },
  };
});

// When initializing the ApolloClient we add a typePolicy rule to InternalUser.meta because the type UserMeta has no
// unique identifier, such as _id, for caching a particular instance. See https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      InternalUser: {
        fields: {
          meta: {
            merge: false,
          },
        },
      },
    },
  }),
});

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {/* <AuthProvider> */}
          <BrandingProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </BrandingProvider>
          {/* </AuthProvider> */}
        </LocalizationProvider>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);
